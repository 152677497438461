<template>
  <div>
    <div v-if="errors.length" class="tw-my-2 alert alert-danger">
      {{ errorMessage }}
    </div>
    <div v-if="showExistingContactMessage" class="tw-my-2 alert alert-warning">
      Er bestaat al een contact met deze gegevens.
      <div id="contact_detail">
        <ul>
          <li v-if="existingContact.display_name">Naam: {{ existingContact.display_name }}</li>
          <li v-if="existingContact.phone">Telefoon: {{ existingContact.phone }}</li>
          <li v-if="existingContact.mobile">Mobile: {{ existingContact.mobile }}</li>
          <li v-if="existingContact.email">E-mail: {{ existingContact.email }}</li>
        </ul>
      </div>
      <a @click="useExistingContact">Selecteren</a>
    </div>
    <div>
      <div id="div_contact_search" class="form-group" style="margin-top: 2px; margin-bottom: 3px;">
        <iam-contact-picker
          ref="contactPicker"
          v-model="selectedContact"
          placeholder="Contact opzoeken..."
          @select="select"
          @deselect="deselect"
        />
      </div>
      <div class="form-group" style="margin-top: 2px; margin-bottom: 3px;">
        <div class="tw-flex tw-flex-row tw-gap-2 tw-my-2">
          <div>
            <label for="persoon" class="tw-mr-2">Persoon</label>
            <input v-model="type" id="persoon" type="radio" value="P" />
          </div>
          <div>
            <label for="bedrijf" class="tw-mr-2">Bedrijf</label>
            <input v-model="type" id="bedrijf" type="radio" value="B" />
          </div>
        </div>
        <div class="row" v-if="type === 'P'">
          <div class="col-sm-6">
            <input
              v-model="firstName"
              type="text"
              placeholder="Voornaam"
              autocomplete="off"
              class="form-control contact-field"
              :style="{ border:errorBorder('firstName') }"
              @blur="existenceCheck(firstName, 'firstName')"
            />
          </div>
          <div class="col-sm-6">
            <input
              v-model="lastName"
              type="text"
              placeholder="Naam"
              autocomplete="off"
              class="form-control contact-field"
              :style="{ border:errorBorder('lastName') }"
              @blur="existenceCheck(lastName, 'lastName')"
            />
          </div>
        </div>
        <div class="row" v-if="type === 'B'">
          <div class="col-sm-12">
            <input
              v-model="companyName"
              type="text"
              placeholder="Bedrijfsnaam"
              autocomplete="off"
              class="form-control contact-field"
              :style="{ border:errorBorder('companyName') }"
              @blur="existenceCheck(firstName, 'companyName')"
            />
          </div>
        </div>
      </div>
      <div class="form-group" style="margin-top: 2px; margin-bottom: 3px;">
        <div class="row">
          <div class="col-sm-6">
            <input
              v-model="phone"
              type="text"
              placeholder="Telefoon"
              autocomplete="off"
              maxlength="20"
              class="form-control contact-field contact-field-check"
              :style="{ border:errorBorder('phone') }"
              @blur="existenceCheck(phone, 'phone')"
            />
          </div>
          <div class="col-sm-6">
            <input
              v-model="mobile"
              type="text"
              placeholder="Gsm"
              autocomplete="off"
              maxlength="16"
              class="form-control contact-field contact-field-check"
              :style="{ border:errorBorder('mobile') }"
              @blur="existenceCheck(mobile, 'mobile')"
            />
          </div>
        </div>
      </div>
      <div class="form-group" style="margin-top: 2px; margin-bottom: 3px;">
        <input
          v-model="email"
          type="email"
          placeholder="E-mail"
          autocomplete="off"
          class="form-control contact-field contact-field-check"
          :style="{ border: errorBorder('email') }"
          @blur="existenceCheck(email, 'email')"
        />
      </div>
    </div>
    <div class="form-group animated fadeInUp" id="pnl-contact-update" v-if="showContactChangesMessage">
      <div class="alert alert-info">
        De aanpassingen aan de contact gegevens zullen worden doorgevoerd.
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ContactPicker from './ContactPicker'

export default {
  name: 'ContactGetOrCreate',
  components: {
    'iam-contact-picker': ContactPicker
  },
  data () {
    return {
      selectedContact: '',
      type: 'P',
      firstName: '',
      lastName: '',
      companyName: '',
      phone: '',
      mobile: '',
      email: '',
      // These are the fields that were filled in at least once.
      // Once added to this list, it is never removed unless the clear method is called.
      // There is no point to validating any field that is not in this list
      usedFields: [],
      // These are the fields that are filled in right now.
      // We use it these to determine when certain messages should be displayed.
      // Example: There is no point in showing the existing contact message if no fields are filled in.
      activeFields: [],
      // These are the fields that should be updated on the selected contact
      // We use these to determine if the contact change message should be shown
      changedFields: [],
      // These are the fields for which we found an existing contact
      // We use these to determine if the existing contact message should be shown
      triggeredFields: [],
      // These are the fields that need to be stopped form being added to usedFields after a clear
      // This is the only array that is not cleared when the clear() method is called.
      // This is needed to prevent weird validation issues ... here be dragons ...
      clearedFields: [],
      errors: [],
      existingContact: '',
      // WARNING: THE FOLLOWING VALUES WILL DRAG YOU DOWN TO DEEPEST PITS OF HELL IF YOU CHANGE THEM!
      doValidate: true,
      doChecks: true
    }
  },
  computed: {
    showContactChangesMessage () {
      // Message that shows when a contact is selected and fields were edited which means the fields will be updated on the selected contact.
      return this.selectedContact && this.changedFields.length > 0
    },
    showExistingContactMessage () {
      // Message that shows when the user edits a field when no contact is selected but a contact exists with this value
      return !this.selectedContact && this.existingContact && this.activeFields.length
    },
    errorMessage () {
      let message = ''
      if (this.errors.length) {
        message = 'Kies een bestaand contact of vul de voornaam, naam en minstens 1 communicatiemethode in, om een nieuw contact toe te voegen.'
        this.errors.forEach((error) => {
          if (error.type === 'invalid_email') {
            message = 'Ongeldig emailadres.'
          }
        })
      }
      return message
    }
  },
  watch: {
    selectedContact (value) {
      this.validate()
      this.$emit('change', value)
      if (value) {
        this.populateFields()
      }
    },
    type (value) {
      this.register('firstName', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('firstName', this.selectedContact.type, value)
      }
    },
    firstName (value) {
      this.register('firstName', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('firstName', this.selectedContact.first_name, value)
      }
    },
    lastName (value) {
      this.register('lastName', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('lastName', this.selectedContact.last_name, value)
      }
    },
    companyName (value) {
      this.register('companyName', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('companyName', this.selectedContact.company_name, value)
      }
    },
    phone (value) {
      this.register('phone', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('phone', this.selectedContact.phone, value)
      }
    },
    mobile (value) {
      this.register('mobile', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('mobile', this.selectedContact.mobile, value)
      }
    },
    email (value) {
      this.register('email', value)
      this.validate()
      if (this.selectedContact) {
        this.fieldChangeCheck('email', this.selectedContact.email, value)
      }
    },
    doValidate (value) {
      // Validation can be stopped and started (mostly to avoid update issues and infinite loops).
      // When it is activated we run validation to see make sure that all changes that happened during validation
      // downtime, are valid
      if (value) {
        this.validate()
      }
    },
    errors () {
      const pickerHasError = this.errors.filter((error) => {
        return error.field === 'selectedContact'
      }).length
      if (pickerHasError) {
        this.$refs.contactPicker.setError()
      } else {
        this.$refs.contactPicker.removeError()
      }
    }
  },
  methods: {
    select (contact) {
      // this code is run when the user selects an existing contact
      this.selectedContact = contact
      this.type = contact.type
      this.firstName = contact.first_name
      this.lastName = contact.last_name
      this.companyName = contact.company_name
      this.phone = contact.phone
      this.mobile = contact.mobile
      this.email = contact.email
    },
    deselect () {
      // this code is run when the user deselects an existing contact
      this.stopChecks()
      this.clearFields()
      this.startChecks()
      this.$refs.contactPicker.enable()
    },
    validate () {
      // This function handles all validation
      if (this.doValidate) {
        this.checkRequired('selectedContact', this.selectedContact, this._pickerIsRequired())
        this.checkRequired('phone', this.phone, this._phoneIsRequired())
        this.checkRequired('mobile', this.mobile, this._mobileIsRequired())
        this.checkRequired('email', this.email, this._emailIsRequired())
        this.checkvalidEmail(this.email)
      }
    },
    clear () {
      // Use when you need to clear the entire form
      this.$refs.contactPicker.clear()
      this.selectedContact = ''
      this.firstName = ''
      this.lastName = ''
      this.companyName = ''
      this.phone = ''
      this.mobile = ''
      this.email = ''
      this.changedFields = []
      this.errors = []
      this.activeFields = []
      this.usedFields = []
      this.triggeredFields = []
      this.existingContact = ''
      this.clearedFields = ['firstName', 'lastName', 'companyName', 'phone', 'mobile', 'email']
    },
    register (field, value) {
      // Register change to a field
      // We keep track of fields that change so we can display messages when they are appopriate

      if (this.usedFields.indexOf(field) === -1 && this.clearedFields.indexOf(field) === -1) {
        this.usedFields.push(field)
      } else {
        const index = this.clearedFields.indexOf(field)
        if (index > -1) {
          this.clearedFields.splice(index, 1)
        }
      }
      if (value) {
        if (this.activeFields.indexOf(field) === -1) {
          this.activeFields.push(field)
        }
      } else {
        const index = this.activeFields.indexOf(field)
        if (index > -1) {
          this.activeFields.splice(index, 1)
        }
      }
      if (!this.activeFields.length) {
        this.existingContact = ''
      }
    },
    clearFields () {
      // Clears all user input fields and logs except the selected contact and existing contact.
      // This is mostly useful when the selected contact is deselected or the selected contact is set to the
      // existing contact.
      // To clear the form completely, use clear()
      this.firstName = ''
      this.lastName = ''
      this.companyName = ''
      this.phone = ''
      this.mobile = ''
      this.email = ''
      this.changedFields = []
      this.errors = []
      this.activeFields = []
      this.triggeredFields = []
    },
    startChecks () {
      // This will automatically trigger the validate() method but NOT the existence or change checks
      this.doValidate = true
      this.doChecks = true
    },
    stopChecks () {
      // Everything that happens from here on out will not trigger any of the following methods until you
      // call startChecks():
      // - validate
      // - existenceCheck
      // - fieldChangeCheck
      this.doValidate = false
      this.doChecks = false
    },
    existenceCheck (value, field) {
      const v = this
      // Checks if a contact exists with the given value for the given field
      // WATCH OUT. This performs an ajax call so use sparingly to not rape the server :-)
      if (!this.selectedContact && this.doChecks && value) {
        $.ajax({
          type: 'get',
          data: {
            query: value
          },
          url: '/api/contacts'
        }).done((data) => {
          if (data.results && data.results.length > 0) {
            if (v.triggeredFields.indexOf(field) === -1) {
              v.triggeredFields.push(field)
            }
            v.existingContact = data.results[0]
          } else {
            const index = v.triggeredFields.indexOf(field)
            if (index > -1) {
              v.triggeredFields.splice(index, 1)
            }
            if (!v.triggeredFields.length) {
              v.existingContact = ''
            }
          }
        }).fail((e) => {
          console.error(e)
        })
      }
    },
    useExistingContact () {
      // Sets the selectedContact to the existingContact
      if (this.existingContact) {
        this.stopChecks()
        this.clearFields()
        this.selectedContact = this.existingContact
        this.existingContact = {}
        this.startChecks()
      }
    },
    populateFields () {
      // Triggered when the selectedContact is set.
      if (this.selectedContact) {
        // this.stopChecks()
        this.clearFields()
        this.type = this.selectedContact.type
        this.register('type', this.type)
        this.firstName = this.selectedContact.first_name
        this.register('firstName', this.firstName)
        this.lastName = this.selectedContact.last_name
        this.register('lastName', this.lastName)
        this.companyName = this.selectedContact.company_name
        this.register('companyName', this.company_name)
        this.phone = this.selectedContact.phone
        this.register('phone', this.phone)
        this.mobile = this.selectedContact.mobile
        this.register('mobile', this.mobile)
        this.email = this.selectedContact.email
        this.register('email', this.email)
        // this.startChecks()
      }
    },
    checkRequired (field, value, isRequired) {
      // Checks if a field is required and if the value is valid
      if (isRequired && value === '' && this.usedFields.indexOf(field) > -1) {
        this.addError(field, 'required')
      } else {
        this.removeError(field, 'required')
      }
    },
    checkvalidEmail (email) {
      // Checks if the given email address is valid
      if (email) {
        if (!this.selectedContact && this.usedFields.indexOf('email') > -1) {
          const re = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(email)) {
            this.removeError('email', 'invalid_email')
          } else {
            this.addError('email', 'invalid_email')
          }
        }
      } else {
        this.removeError('email', 'invalid_email')
      }
    },
    addError (field, type) {
      this.errors.push({
        field: field,
        type: type
      })
    },
    removeError (field, type) {
      for (let i = this.errors.length - 1; i > -1; i--) {
        if (this.errors[i].field === field && this.errors[i].type === type) {
          this.errors.splice(i, 1)
        }
      }
    },
    fieldChangeCheck (field, originalValue, newValue) {
      // When a contact is selected, this field checks for any changes are made to the populated fields.
      if (this.doChecks) {
        if (this.selectedContact && newValue !== originalValue && this.changedFields.indexOf(field) === -1) {
          this.changedFields.push(field)
        } else {
          for (let i = this.changedFields.length - 1; i > -1; i--) {
            if (this.changedFields[i] === field) {
              this.changedFields.splice(i, 1)
            }
          }
        }
      }
    },
    errorBorder (field) {
      const fieldHasError = this.errors.filter((error) => {
        return error.field === field
      }).length
      if (fieldHasError && this.usedFields.indexOf(field) > -1) {
        return '1px solid red'
      } else {
        return ''
      }
    },
    getContact () {
      return {
        type: this.type,
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.companyName,
        phone: this.phone,
        mobile: this.mobile,
        email: this.email,
        ...this.selectedContact
      }
    },
    _pickerIsRequired () {
      return this.selectedContact === '' && ((this.firstName === '' ||
          this.lastName === '' || this.companyName === '') || (this.mobile === '' &&
          this.email === '' && this.phone === ''))
    },

    _phoneIsRequired () {
      return (this.selectedContact === '' || this.selectedContact === null) && this.mobile === '' && this.email === ''
    },
    _mobileIsRequired () {
      return (this.selectedContact === '' || this.selectedContact === null) && this.phone === '' && this.email === ''
    },
    _emailIsRequired () {
      return (this.selectedContact === '' || this.selectedContact === null) && this.phone === '' && this.mobile === ''
    }
  }
}
</script>
