<template>
  <Modal ref="modal" title="Nieuwe kandidaat">
    <div class="row">
      <div class="col-sm-12">
        <form class="form-horizontal">

          <div class="form-group">
            <label class="control-label">Rol</label>
            <select class="form-control" v-model="type">
              <option value="2">Huurder</option>
              <option value="3">Borgsteller</option>
              <option value="1">Koper</option>
            </select>
          </div>

          <div class="form-group">
            <ContactGetOrCreate ref="contactPicker"></ContactGetOrCreate>
          </div>
        </form>

        <div class="form-group">
          <div class="col-sm-12">
            <a class="btn btn-primary col-sm-3 m-r" @click="saveCandidate">Opslaan</a>
            <a class="btn btn-default col-sm-3" @click="hide">Annuleren</a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import ContactGetOrCreate from '@/components/contacts/ContactGetOrCreate'
import { mapActions } from 'vuex'
import { startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'

export default {
  name: 'CandidateModal',
  components: { ContactGetOrCreate, Modal },
  data () {
    return {
      type: 2,
      contact: null
    }
  },
  mounted () {
    this.$refs.contactPicker.clear()
  },
  methods: {
    ...mapActions('contacts', ['saveContact']),
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async saveCandidate () {
      try {
        startLoadingModal('Kandidaat wordt aangemaakt...')
        if (this.contact.id === null) {
          const contact = await this.saveContact(this.contact)
          this.$emit('save', this.type, contact)
        } else {
          this.$emit('save', this.type, this.contact)
        }
        stopLoadingModal()
      } catch (error) {
        errorModal('Fout bij maken van kandidaat, probeer het opnieuw')
      }
    }
  }
}
</script>
