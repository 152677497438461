<template>
  <div v-if="Object.keys(contact).length">
    <h3>
      {{ contact.first_name }} {{ contact.last_name}}
      <span class="label label-info" v-if="candidate.role === 3">Borgsteller</span>
      <router-link
        target="_blank"
        :to="{ name: 'ContactDetails', params: { id: contact.id } }"
        class="btn btn-xs btn-default"
      >
        <i class="glyphicon glyphicon-search" />
      </router-link>
      <a @click="removeCandidateHandler" class="btn btn-xs btn-danger">
        <i class="glyphicon glyphicon-trash" />
      </a>
    </h3>

    <div class="border-left-blue">
      <CandidateIncome :candidate="candidate" />
      <p class="indenty">
        <strong>Kopie identiteitskaart</strong>
        <a @click="showUploadModal" class="btn btn-sm btn-default">Uploaden</a>
      </p>
    </div>
    <ContactAttachmentModal ref="contactAttachmentModal" :contact-id="candidate.relation" />
  </div>
</template>

<script>
import CandidateIncome from '@/components/sales/CandidateIncome'
import ContactAttachmentModal from '@/components/contacts/ContactAttachmentModal'
import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'
import { getContactById } from '@/services/contacts'

export default {
  name: 'RenterProfileCandidate',
  components: { ContactAttachmentModal, CandidateIncome },
  props: {
    candidate: Object
  },
  data () {
    return {
      contact: {}
    }
  },
  created () {
    this.init(this.candidate.relation)
  },
  methods: {
    ...mapActions('sales', ['removeCandidate']),

    async init (contactId) {
      try {
        const response = await getContactById(contactId)
        this.contact = response.data
        return response
      } catch (error) {
        errorModal('Kan gegevens niet laden, gelieve opnieuw te proberen')
      }
    },
    showUploadModal () {
      this.$refs.contactAttachmentModal.show()
    },
    async removeCandidateHandler () {
      try {
        await this.removeCandidate(this.candidate)
        successModal('Kandidaat is succesvol verwijderd')
      } catch {
        errorModal('Kan kandidaat niet verwijderen, gelieve opnieuw te proberen')
      }
    }
  }
}
</script>

<style scoped>
  p.indenty {
    margin-left: 10px;
  }

  div.border-left-blue {
    border-left: #1a7bb9 2px solid;
  }
</style>
