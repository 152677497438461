<template>
  <div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Inkomsten</th>
        <th class="text-right">Bedrag</th>
        <th>Documentatie</th>
        <th style="text-align: right">
          <a class="btn btn-xs btn-default" @click="showIncomeModal">
            <i class="glyphicon glyphicon-plus-sign"></i> nieuwe inkomsten
          </a>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="income in incomes" :key="income.id">
        <td>{{ income.type_display }}</td>
        <td class="text-right">&euro; {{ income.value|currency }}</td>
        <td>
        <span v-for="documentation in income.documentation" :key="documentation.id">
          <a class="btn btn-xs btn-default" :href="documentation.url" target="_blank">
            <i class="glyphicon glyphicon-download-alt"></i> downloaden
          </a>
        </span>
        </td>
        <td style="text-align: right">
          <a class="btn btn-xs btn-default" @click="showUploadModal(income.id)">
            <i class="glyphicon glyphicon-upload"></i> uploaden
          </a>
          <a class="btn btn-xs btn-danger" @click="deleteIncome(income.id)">
            <i class="glyphicon glyphicon-trash"></i> verwijderen
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <CandidateIncomeModal ref="modal" @save="saveIncome" />
    <CandidateIncomeDocumentationModal ref="incomeDocumentationModal" @uploadedNewDocumentation="finishedUploading"></CandidateIncomeDocumentationModal>
  </div>
</template>

<script>
import CandidateIncomeModal from '@/components/sales/CandidateIncomeModal'
import CandidateIncomeDocumentationModal from '@/components/sales/CandidateIncomeDocumentationModal'

import StoreIntegration from '@/store/integration'
import { errorModal, startLoadingModal, questionModal, successModal } from '@/modalMessages'
import { deleteSalesIncome } from '@/services/sales'

export default {
  name: 'CandidateIncome',
  mixins: [StoreIntegration],
  components: { CandidateIncomeDocumentationModal, CandidateIncomeModal },
  props: ['candidate'],
  computed: {
    incomes () {
      return this.getFromStore('sales/getCandidateIncomes', { id: this.candidate.id }, 'sales/getCandidateIncomes', this.candidate.id)
    }
  },
  methods: {
    showIncomeModal () {
      this.$refs.modal.show()
    },
    async saveIncome (phase, value, type) {
      try {
        startLoadingModal('Inkomen wordt geregistreerd...')
        const payload = { candidate: this.candidate.id, phase, value, type }
        const response = await this.$store.dispatch('sales/saveIncome', payload)
        await this.$store.dispatch('sales/loadRenterProfileForLead', { id: this.candidate.match })
        this.$refs.modal.hide()
        successModal('Inkomen succesvol opgeslagen.')
        return response
      } catch (error) {
        errorModal('Fout bij opslaan van inkomsten, probeer het opnieuw.')
      }
    },
    async deleteIncome (incomeId) {
      try {
        const result = await questionModal('Wil je deze inkomsten verwijderen? Dit zal eveneens de bijlagen voor deze inkomsten verwijderen en kan niet ongedaan gemaakt worden.')
        if (!result.value) return

        startLoadingModal('Inkomsten verwijderen...')
        const response = await deleteSalesIncome(incomeId)
        await Promise.all([
          this.$store.dispatch('sales/getCandidateIncomes', this.candidate.id),
          this.$store.dispatch('sales/loadRenterProfileForLead', { id: this.candidate.match })
        ])
        successModal('Inkomsten verwijderd.')
        return response
      } catch (error) {
        errorModal('Fout bij verwijderen van inkomsten, probeer het opnieuw.')
      }
    },
    showUploadModal (incomeId) {
      this.$refs.incomeDocumentationModal.income = incomeId
      this.$refs.incomeDocumentationModal.show()
    },
    finishedUploading () {
      this.$store.dispatch('sales/getCandidateIncomes', this.candidate.id)
    }
  }
}
</script>
