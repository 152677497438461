<template>
  <Modal ref="modal" title="Bestand toevoegen aan een contact">
    <div class="row">
      <div class="col-sm-12">
        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-2 control-label">Type</label>
            <div class="col-sm-4">
              <select class="form-control" v-model="type">
                <option value="0">Onbekend</option>
                <option value="1">Identiteitsbewijs</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <FileDropzone :url="fileUploadUrl" @file-uploaded="finishedUploading" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import FileDropzone from '@/components/iam/FileDropzone'

export default {
  name: 'ContactAttachmentModal',
  components: { FileDropzone, Modal },
  props: {
    contactId: Number
  },
  data () {
    return {
      type: 1
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    finishedUploading () {
      this.$emit('uploadedAttachment')
    }
  },
  computed: {
    fileUploadUrl () {
      return `/api/contacts/${this.contactId}/files?type=${this.type}`
    }
  }
}
</script>
