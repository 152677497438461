<template>
  <Modal ref="modal" title="Inkomsten toevoegen">
    <div class="row">
      <div class="col-sm-12">
        <form class="form-horizontal">
          <div class="form-group">
            <label class="control-label">Type</label>
            <select class="form-control" v-model="type">
              <option value="0">Nettoloon</option>
              <option value="1">Pensioen</option>
              <option value="2">Uitkering</option>
              <option value="3">Alimentatie</option>
              <option value="4">Kindergeld</option>
              <option value="5">Interesten</option>
              <option value="6">Spaargeld</option>
              <option value="7">Andere</option>
            </select>
          </div>

          <div class="form-group">
            <label class="control-label">Bedrag</label>
            <input type="number" class="form-control" v-model="value">
          </div>
        </form>

        <div class="form-group">
          <div class="col-sm-12">
            <a class="btn btn-primary col-sm-3 m-r" @click="saveIncome">Opslaan</a>
            <a class="btn btn-default col-sm-3" @click="hide">Annuleren</a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'

export default {
  name: 'CandidateIncomeModal',
  components: { Modal },
  data () {
    return {
      value: 0,
      type: 0
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    saveIncome () {
      this.$emit('save', this.phase, this.value, this.type)
    }
  }
}
</script>
