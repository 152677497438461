<template>
  <Modal ref="modal" title="Inkomensten documentatie uploaden">
    <div>
      <div class="alert alert-info" v-if="this.message">
        {{ message }}
      </div>
      <div class="row">
        <div class="col-sm-12">
          <FileDropzone :url="fileUploadUrl" @file-uploaded="finishedUploading" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'
import FileDropzone from '../iam/FileDropzone'

export default {
  name: 'CandidateIncomeDocumentationModal',
  components: { FileDropzone, Modal },
  data () {
    return {
      income: null,
      message: null
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    finishedUploading () {
      this.$emit('uploadedNewDocumentation')
      this.message = 'Bijlage is geüpload'
    }
  },
  computed: {
    fileUploadUrl () {
      return '/api/sales/income/' + this.income + '/documentation'
    }
  }
}
</script>

<style scoped>

</style>
