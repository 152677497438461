<!--

RenterProfile.vue

Displays the renter profile for a candidate

Authors:
 Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
-->
<template>
  <div>
    <div>
      <div v-if="editObject">
        <p>
          <a @click="saveProfile" class="btn btn-default">
            <i class="glyphicon glyphicon-floppy-disk"></i> Opslaan
          </a>
          <a @click="profileReport" class="btn btn-default">
            <i class="glyphicon glyphicon-user"></i> Kandidatenrapport
          </a>
        </p>

        <h2>Gezinssamenstelling</h2>
        <div class="row">
          <div class="col-sm-6">
            <dl class="dl-horizontal">
              <dt>Volwassenen</dt>
              <dd>
                <input class="form-control input-sm" v-model="editObject.adults">
              </dd>
              <dt>Kinderen</dt>
              <dd>
                <input class="form-control input-sm" v-model="editObject.children">
              </dd>
              <dt>Huisdieren</dt>
              <dd>
                <textarea class="form-control" v-model="editObject.pets"></textarea>
              </dd>
            </dl>
          </div>
          <div class="col-sm-6">
          </div>
        </div>

        <h2>Huur informatie</h2>
        <dl class="dl-horizontal">
          <dt>Datum huurintentie</dt>
          <dd>
            <input class="form-control input-sm" type="date" v-model="editObject.rent_start_date">
          </dd>
          <dt>Reden</dt>
          <dd>
            <select class="form-control" v-model="editObject.rent_intention">
              <option value="0">Onbekend</option>
              <option value="1">Eerste woning</option>
              <option value="2">Budgetaire redenen</option>
              <option value="3">Gezinsuitbreiding</option>
              <option value="4">Grotere woning</option>
              <option value="5">Andere</option>
            </select>
          </dd>
          <dt>Huidig adres</dt>
          <dd>
            <div class="row">
              <div class="col-sm-8">
                <input class="form-control" placeholder="straat" type="text" v-model="editObject.current_street">
              </div>
              <div class="col-sm-2">
                <input class="form-control" placeholder="nr" type="text" v-model="editObject.current_number">
              </div>
              <div class="col-sm-2">
                <input class="form-control" placeholder="bus" type="text" v-model="editObject.current_box">
              </div>
            </div>
          </dd>
          <dt></dt>
          <dd>
            <CityPicker v-model="editObject.current_city"></CityPicker>
          </dd>
          <dt>Ik woon er als</dt>
          <dd>
            <select class="form-control" v-model="editObject.current_residence_type">
              <option value="1">Eigenaar</option>
              <option value="2">Huurder</option>
            </select>
          </dd>
        </dl>
        <dl class="dl-horizontal" v-if="editObject.current_residence_type == 2">
          <dt>Huidige huisbaas</dt>
          <dd>
            <div class="row">
              <div class="col-sm-6">
                <input class="form-control" placeholder="Naam huisbaas" type="text"
                       v-model="editObject.current_tenancy.last_name">
              </div>
              <div class="col-sm-6">
                <input class="form-control" placeholder="Voornaam huisbaas" type="text"
                       v-model="editObject.current_tenancy.first_name">
              </div>
            </div>
          </dd>
          <dt></dt>
          <dd>
            <div class="row">
              <div class="col-sm-6">
                <input class="form-control" placeholder="GSM / Telefoon" type="text"
                       v-model="editObject.current_tenancy.phone">
              </div>
              <div class="col-sm-6">
                <input class="form-control" placeholder="E-mail" type="text" v-model="editObject.current_tenancy.email">
              </div>
            </div>
          </dd>
          <dt>Huidige huurprijs</dt>
          <dd>
            <CurrencyInput v-model="editObject.current_tenancy.current_price"></CurrencyInput>
          </dd>
        </dl>

        <h2>
          Inkomsten
          <a @click="showCandidateModal" class="btn btn-xs btn-default">
            <i class="glyphicon glyphicon-plus-sign"></i> Huurder toevoegen
          </a>
        </h2>
        <dl class="dl-horizontal">
          <dt>Verklaring inkomsten</dt>
          <dd>
            <select class="form-control" v-model="editObject.income_statement">
              <option value="0">Onbekend</option>
              <option value="1">Wil niet vrijgeven</option>
              <option value="2">Verdient te weinig</option>
              <option value="3">Verdient genoeg</option>
            </select>
          </dd>
        </dl>

        <div :key="candidate.id" class="p-xs" v-for="candidate in profile.candidates">
          <RenterProfileCandidate :candidate="candidate"></RenterProfileCandidate>
        </div>

        <h2>Financiële screening</h2>

        <div class="form-group row">
          <div class="col-sm-2 col-form-label">Totaal inkomen</div>
          <div class="col-sm-8">
            &euro; {{ profile.total_income|currency }}
            <small>(alle inkomsten uitgezonderd spaargeld en andere)</small>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Status</label>

          <div class="col-sm-8">
            <div><label> <input type="radio" v-model="status" value="0"> On hold (nog niet alle documenten
              beschikbaar)</label></div>
            <div><label> <input type="radio" v-model="status" value="2"> Komt financieel in aanmerking</label>
            </div>
            <div><label> <input type="radio" v-model="status" value="1"> Komt financieel niet in
              aanmerking</label></div>
          </div>
        </div>

        <h2>Opmerkingen</h2>
        <dl class="dl-horizontal">
          <dt>Opmerkingen</dt>
          <dd>
            <textarea class="form-control" v-model="editObject.comments_external"></textarea>
          </dd>
          <dt>Opmerkingen makelaar</dt>
          <dd>
            <textarea class="form-control" v-model="editObject.comments_internal"></textarea>
          </dd>
        </dl>

      </div>
      <div v-else>
        <p><a @click="createProfile" class="btn btn-primary">Huurdersprofiel aanmaken</a></p>
      </div>
    </div>
    <CandidateModal @save="addNewCandidate" ref="candidateModal"></CandidateModal>
  </div>
</template>

<script>
import moment from 'moment'
import RenterProfileCandidate from './RenterProfileCandidate'
import CandidateModal from '../sales/CandidateModal'
import CityPicker from '../properties/CityPicker'
import CurrencyInput from '../iam/CurrencyInput'
import { mapGetters, mapActions } from 'vuex'
import { errorModal, successModal, stopLoadingModal } from '@/modalMessages'

export default {
  name: 'RenterProfile',
  components: {
    CurrencyInput,
    CityPicker,
    CandidateModal,
    RenterProfileCandidate
  },
  props: ['lead'],
  mounted () {
    this.loadRenterProfileForLead({ id: this.lead })
  },
  data () {
    return {
      status: 0,
      editObject: null
    }
  },
  computed: {
    ...mapGetters('sales', ['getRenterProfileForLead']),
    profile () {
      return this.getRenterProfileForLead(this.lead)
    }
  },
  watch: {
    status () {
      const data = JSON.parse(JSON.stringify(this.profile))
      data.status = this.status
      this.saveRenterProfile(data)
    },
    profile () {
      if (this.profile) {
        this.status = this.profile.status
        this.editObject = Object.assign({}, this.profile)
        this.editObject.rent_start_date = moment(this.editObject.rent_start_date).format('YYYY-MM-DD')
        if (!this.editObject.current_tenancy) {
          this.editObject.current_tenancy = {
            first_name: '',
            last_name: '',
            phone: '',
            email: ''
          }
        } else {
          this.editObject.current_tenancy = Object.assign({}, this.editObject.current_tenancy)
        }
      }
    }
  },
  methods: {
    ...mapActions('sales', [
      'loadRenterProfileForLead',
      'saveRenterProfile',
      'createRenterProfile',
      'saveCandidate',
      'saveTenancy'
    ]),
    reload () {
      if (this.lead) {
        this.loadRenterProfileForLead({ id: this.lead })
      }
    },
    createProfile () {
      this.createRenterProfile({ match: this.lead })
    },
    showCandidateModal () {
      this.$refs.candidateModal.show()
    },
    async addNewCandidate (type, contact) {
      try {
        await this.saveCandidate({
          match: this.lead,
          role: type,
          contact: contact.id
        })
        stopLoadingModal()
        this.$refs.candidateModal.hide()
      } catch (err) {
        errorModal('Kan kandidaat niet toevoegen, gelieve opnieuw te proberen')
      }
    },
    async saveProfile () {
      const data = Object.assign({}, this.editObject)
      let currentTenancy = null
      if (data.current_residence_type === 2 || data.current_residence_type === '2') {
        currentTenancy = {
          renter_profile: this.profile.id,
          first_name: data.current_tenancy.first_name,
          last_name: data.current_tenancy.last_name,
          phone: data.current_tenancy.phone,
          email: data.current_tenancy.email,
          current_price: data.current_tenancy.current_price
        }
      }

      delete data.current_tenancy

      if (data.current_city) {
        data.current_city = data.current_city.id
      }

      data.rent_start_date = moment(data.rent_start_date, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss')

      if (data.rent_start_date === 'Invalid date') {
        await errorModal('Men moet een datum van huurintentie invullen. Gelieve nogmaals te proberen.')
      } else {
        try {
          await this.saveRenterProfile(data)
          if (currentTenancy) await this.saveTenancy(currentTenancy)
          await successModal('De wijzigingen zijn doorgevoerd')
        } catch (e) {
          await errorModal('Kan huurdersprofiel niet opslaan, gelieve opnieuw te proberen')
        }
      }
    },
    profileReport () {
      window.open(`/api/sales/renterprofile/${this.profile.id}/report`, '_blank')
    }
  }
}
</script>
<style scoped>
</style>
