<template>
  <input class="form-control" v-model="amount">
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: ['value'],
  data () {
    return {
      amount: 0
    }
  },
  created () {
    this.amount = this.value
  },
  watch: {
    value () {
      this.amount = this.value
    },
    amount () {
      this.amount = this.amount.replace(',', '.')
      this.$emit('input', this.amount)
    }
  }
}
</script>

<style scoped>

</style>
